











































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MainTabs from "@/components/MainTabs.vue";
import UserService from "@/services/UserService";
import Avatar from "@/components/Avatars/Avatar.vue";
import AvatarInstructor from "@/components/Avatars/AvatarInstructor.vue";
import AuthImage from "@/directives/AuthImage";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { IMainTab } from "@/interfaces/IMainTab";
import RefreshButton from "@/components/Button/RefreshButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { formatInstructorName } from "@/utils/NameUtil";

const MainTabsModule = namespace("main-tabs");
const FilterModule = namespace("filtering");
const PulseModule = namespace("pulse");

@Component({
  methods: {
    formatInstructorName,
  },
  components: { AbortButton, RefreshButton, MainTabs, Avatar, AvatarInstructor, LocaleSwitcher },
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class Navbar extends Vue {
  public name = "Navbar";
  public nodeEnvironment = process.env.VUE_APP_ENV;
  public avatarUpdated = true;

  @MainTabsModule.Getter("getActive")
  private activeTab!: IMainTab;

  @MainTabsModule.Getter("getTabs")
  private tabs: any;

  @FilterModule.Mutation("SET_FILTER")
  public setSearchTerm!: any;

  @PulseModule.Action("findOne")
  public findPulseNotification!: any;

  @PulseModule.Getter("getDataItem")
  public newNotification!: any;

  @PulseModule.Action("update")
  public updatePulse!: any;

  public user = this.currentUser || null;

  public get currentUser(): any {
    return UserService.getUser();
  }

  protected async refreshPage(): Promise<void> {
    await this.$root.$emit("refresh-page", this.activeTab);
  }

  public async mounted() {
    this.$root.$on("refresh-page-after-update", () => {
      this.refreshPage();
    });
    this.$root.$on("avatar-updated", () => {
      this.avatarUpdated = false;
      setTimeout(() => {
        this.avatarUpdated = true;
      }, 0);
    });
    await this.findPulseNotification({
      resource: "users",
      id: "pulse-notification",
    });
  }

  public onCloseAllTabs() {
    (this.$refs.mainTabs as MainTabs).onCloseAllTabs();
  }

  public async onPulseClick() {
    await this.$router.push({ name: "Pulse" });
    await this.updatePulse({
      resource: "users",
      id: "pulse-notification-seen",
      hideSuccessMessage: true,
    });
    await this.findPulseNotification({
      resource: "users",
      id: "pulse-notification",
    });
  }

  public get isNotificationNew() {
    return this.newNotification;
  }
}
