










































import { Component, Vue } from "vue-property-decorator";
import MainSidebarItem from "@/components/MainSidebar/MainSidebarItem.vue";

import fscLogo from "@/assets/fsc_simple_logo.png";
import lmsLogo from "@/assets/lms-drivebuzz.png";
import ActionButton from "@/components/Button/ActionButton.vue";
import { namespace } from "vuex-class";
const MainTabsModule = namespace("main-tabs");
@Component({
  components: { ActionButton, MainSidebarItem },
})
export default class MainSidebar extends Vue {
  public name = "MainSidebar";

  public fscLogo: string = fscLogo;
  public lmsLogo: string = lmsLogo;

  @MainTabsModule.Getter("getActiveContext")
  private getActiveContextAction: any;

  public signOut(): void {
    this.$router.push({ name: "Logout" });
  }

  public setFSCSidebarOn(): void {
    this.$emit("fsc-sidebar-click");
  }

  public setLMSSidebarOn(): void {
    this.$emit("lms-sidebar-click");
  }
}
